/*! _dashboard-navbar.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Dashboard navbar styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Navbar
1. Quick Search
2. Search Modal
3. Navbar dropdowns
4. Mobile Nav
=============================================================================
***/

/* ==========================================================================
0. Navbar
========================================================================== */

//Pushed navbar state when sidebar is open
.is-pushed {
    transform: translateX(250px);
}

//Dashboard Navbar
.dashboard-nav {
    margin-left: 80px;
    position: fixed;
    width: 100%;
    right: 0;
    left: 0;
    height: 60px;
    max-width: calc(100% - 80px);
    background: transparent;
    padding: 0 50px;
    transition: all .3s;

    &.is-scrolled {
        background: $white;
        box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.12);
    }

    .navbar-brand {
        margin-left: 0 !important;
        align-items: center;
        flex-grow: 2;

        .searchbox {
            margin-right: auto;
            flex-grow: 2;

            input {
                width: 100%;
                height: 40px;
                padding-left: 40px;
                border-color: darken($fade-grey, 3%);

                &:focus {
                    box-shadow: $light-box-shadow;
                    border-color: darken($fade-grey, 4%);

                    + .form-icon {
                        svg {
                            stroke: $secondary;
                        }
                    }
                }
            }

            .form-icon {
                position: absolute;
                top: 0;
                left: 0;
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    height: 18px;
                    width: 18px;
                    stroke: $placeholder;
                    transition: stroke .3s;
                }
            }
        }

        .hamburger-btn {
            margin-left: auto;
        }

        .menu-toggle {
            font-size: 20px;
            color: #666;
            line-height: 48px;
            text-align: center;
            background: transparent;
            display: block;
            width: 24px;
            height: 26px;
            cursor: pointer;
            padding: 0;
            margin: 0 14px;
            transition: opacity 0.4s;
            opacity: 1;
            position: relative;
            top: 2px;

            .icon-box-toggle {
                height: 100%;
                width: 100%;
                background: tranparent;
                position: relative;
                display: block;
                width: 30px;
                height: 30px;

                &.active > span.rotate {
                    /*transform*/
                    -webkit-transform: rotate(90deg);
                    -moz-transform: translate(0px, 0px) rotate(90deg);
                    -ms-transform: translate(0px, 0px) rotate(90deg);
                    -o-transform: translate(0px, 0px) rotate(90deg);
                    transform: translate(0px, 0px) rotate(90deg);
                }

                &.active > span > i.icon-line-center {
                    visibility: hidden;
                    width: 1px;
                    height: 3px;
                    left: 70%;
                }

                &.active > span > i.icon-line-bottom {
                    margin: -2px 0 0 -10px;
                    left: 50%;
                    top: 12px;

                    /*transform*/
                    -webkit-transform: rotate(135deg);
                    -moz-transform: translate(0px, 0px) rotate(135deg);
                    -ms-transform: translate(0px, 0px) rotate(135deg);
                    -o-transform: translate(0px, 0px) rotate(135deg);
                    transform: translate(0px, 0px) rotate(135deg);
                }

                &.active > span > i.icon-line-top {
                    margin: -2px 0 0 -10px;
                    left: 50%;
                    top: 12px;

                    /*transform*/
                    -webkit-transform: rotate(45deg);
                    -moz-transform: translate(0px, 0px) rotate(45deg);
                    -ms-transform: translate(0px, 0px) rotate(45deg);
                    -o-transform: translate(0px, 0px) rotate(45deg);
                    transform: translate(0px, 0px) rotate(45deg);
                }
            }

            .icon-line-center {
                position: absolute;
                width: 20px;
                height: 2px;
                background: $title-grey;
                margin: -1px 0 0 -10px;
                left: 50%;
                top: 11px;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

            .icon-line-top {
                position: absolute;
                width: 20px;
                height: 2px;
                background: $title-grey;
                margin: -3px 0 0 -10px;
                left: 50%;
                top: 7px;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }

            .icon-line-bottom {
                position: absolute;
                width: 20px;
                height: 2px;
                background: $title-grey;
                margin: 2px 0 0 -10px;
                left: 50%;
                top: 14px;
                -webkit-transition: all 0.2s ease;
                -moz-transition: all 0.2s ease;
                -o-transition: all 0.2s ease;
                transition: all 0.2s ease;
            }
        }
    }

    .navbar-menu {
        .navbar-end {
            display: flex;
            align-items: center;

            .icon-button {
                position: relative;
                height: 46px;
                width: 46px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 1px solid transparent;
                cursor: pointer;
                margin-left: 4px;
                transition: all .3s;

                &:hover {
                    background: $white;
                    border-color: darken($fade-grey, 3%);
                    box-shadow: $light-box-shadow;
                }

                svg {
                    height: 18px;
                    width: 18px;
                    stroke: $muted-grey;
                    stroke-width: 1.6px;
                    transition: stroke .3s;
                }

                img {
                    display: block;
                    height: 36px;
                    width: 36px;
                    border-radius: 50%;
                }

                .indicator {
                    position: absolute;
                    display: block;
                    top: 4px;
                    right: 0px;
                    height: 8px;
                    width: 8px;
                    border-radius: 50%;
                    background: $primary;

                    &.is-alt {
                        background: $secondary;
                    }
                }
            }
        }
    }

    //Responsive menu toggle
    .nav-toggle {
        height: 65px !important;
        width: 65px !important;
    }

    //nav items
    .navbar-item {
        &.reader-switch .field {
            padding-top: 7px;
        }
    }
}

/* ==========================================================================
3. Navbar dropdowns
========================================================================== */

//Pop dropdowns setup
.drop-pop {
    position: relative;

    //dropdown wrapper
    .drop-wrapper {
        position: absolute;
        top: 65px;
        right: -10px;
        border-radius: 3px 3px 5px 5px;
        background: $white;
        min-width: 250px;
        min-height: 100px;
        max-height: 300px;
        box-shadow: $light-box-shadow;
        opacity: 0;
        transform: translateY(5px);
        pointer-events: none;
        transition: all .3s;

        //inner section
        .drop-inner {
            position: relative;
            background: $white;
            text-align: left;
            border: 1px solid $fade-grey;
            border-radius: 3px 3px 5px 5px;
            width: 100%;
            height: 100%;

            ul {
                padding: 10px 0;
            }

            //manages the small tooltip arrow
            &.has-arrow:after, &.has-arrow:before {
                bottom: 100%;
                right: 7%;
                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
            }

            &.has-arrow:after {
                border-color: rgba(255, 255, 255, 0);
                border-bottom-color: $white;
                border-width: 8px;
                margin-left: -8px;
            }

            &.has-arrow:before {
                border-color: rgba(237, 237, 237, 0);
                border-bottom-color: $fade-grey;
                border-width: 9px;
                margin-left: -9px;
            }
        }

        //active state
        &.is-active {
            opacity: 1;
            transform: translateY(0);
            pointer-events: all;
        }
    }
}

//Notifications dropdown
.notifications-drop {
    width: 320px;
    right: -5px;
    animation: fadeInDown .5s;

    //header
    .notifications-header {
        width: 100%;
        padding: 16px;
        background: $white;
        border-bottom: 1px solid $fade-grey;

        h3 {
            font-size: 1.2rem;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            color: $blue-grey;
        }
    }

    //body
    .notifications-body {
        background: $white;
        padding: 20px 10px 5px 8px;
        margin-left: -1px;

        .notifications-list {
            padding: 0;
            position: relative;

            li {
                position: relative;
                margin-bottom: 30px;
                cursor: pointer;

                &:after {
                    content: '';
                    width: 1.5px;
                    left: 20px;
                    height: 30px;
                    background-color: $title-grey;
                    position: absolute;
                    opacity: 0.3;
                }

                &:first-child {
                    &:before {
                        content: '';
                        width: 1.5px;
                        left: 20px;
                        top: -24px;
                        height: 23px;
                        background-color: $title-grey;
                        position: absolute;
                        opacity: 0.3;
                    }
                }

                i {
                    width: 40px;
                    height: 40px;
                    display: block;
                    text-align: center;
                    line-height: 42px;
                    font-size: 20px;
                    color: $placeholder;

                    &.red {
                        color: $red;
                    }
                }
            }

            //content
            .notification-content {
                position: absolute;
                margin-left: 50px;
                margin-top: -39px;
                display: flex;

                //align-items: center;

                //avatar
                img {
                    width: 38px;
                    height: 38px;
                    max-height: 38px;
                    border-radius: 50%;
                }

                //text
                .notification-text {
                    .text {
                        font-size: 1rem;
                        margin-left: 10px;
                        color: $muted-grey;

                        b {
                            color: $blue-grey;
                        }
                    }

                    .timestamp {
                        font-size: 85%;
                        margin-left: 10px;
                        color: $title-grey;
                    }
                }
            }
        }
    }
}

//Messages dropdown
.emails-drop {
    width: 320px;
    right: -5px;
    animation: fadeInDown .5s;

    //dropdown header
    .emails-header {
        width: 100%;
        padding: 16px;
        background: $white;
        border-bottom: 1px solid $fade-grey;

        h3 {
            font-size: 1.2rem;
            font-family: 'Montserrat', sans-serif;
            font-weight: 600;
            color: $blue-grey;
        }
    }

    //dropdown body
    .emails-body {
        background: $white;
        padding: 20px 10px 5px 7px;
        margin-left: -1px;

        //item list
        .emails-list {
            padding: 0;
            position: relative;

            li:after {
                content: '';
                width: 1.5px;
                left: 24px;
                height: 18px;
                bottom: -24px;
                background-color: $title-grey;
                position: absolute;
                opacity: 0.3;
            }

            li:first-child:before {
                content: '';
                width: 1.5px;
                left: 24px;
                top: -24px;
                height: 18px;
                background-color: $title-grey;
                position: absolute;
                opacity: 0.3;
            }

            li {
                margin-bottom: 30px;
                position: relative;
                cursor: pointer;

                img, .fake-avatar {
                    width: 40px;
                    height: 40px;
                    min-height: 40px;
                    border-radius: 50%;
                    display: block;
                    text-align: center;
                    line-height: 38px;
                    font-size: 16px;
                    font-weight: 600;
                    font-family: 'Montserrat', sans-serif;
                    color: $white !important;
                    margin-left: 4px;
                    background: $muted-grey;

                    &.is-primary {
                        background: $primary;
                    }

                    &.is-secondary {
                        background: $secondary;
                    }
                }

                .fake-avatar {
                    line-height: 2.4;
                }
            }

            //item content
            .email-content {
                position: absolute;
                margin-left: 50px;
                margin-top: -38px;
                display: flex;
                align-items: center;

                .email-text {
                    .text {
                        font-size: 90%;
                        font-weight: 500;
                        margin-left: 10px;
                        color: $blue-grey;

                        &.is-read {
                            opacity: 0.7;
                            color: $title-grey;
                            font-weight: 400;
                        }
                    }

                    .timestamp {
                        font-size: 70%;
                        margin-right: 10px;
                        color: $blue-grey;
                        font-weight: 400;
                        float: right;
                    }
                }
            }
        }
    }
}

//Accounts

.accounts-drop {
    width: 320px;
    right: -5px;
    animation: fadeInDown .5s;

    .drop-inner {
        .head-block {
            text-align: center;
            padding: 30px;
            border-bottom: 1px solid darken($fade-grey, 3%);

            img {
                display: block;
                height: 80px;
                width: 80px;
                border-radius: 50%;
                margin: 0 auto 10px auto;
            }

            h3 {
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                font-size: 1.1rem;
                color: $blue-grey;
            }

            p {
                font-size: 1rem;
                color: $muted-grey;
                line-height: 1;
                margin-bottom: 16px;
            }

            .button {
                min-width: 140px;
                font-size: .9rem;
                height: 36px;
                padding-bottom: 6px;
                font-weight: 500;
                color: $blue-grey;
            }
        }

        .accounts-block {
            padding: 20px 0;
            border-bottom: 1px solid darken($fade-grey, 3%);

            .account {
                display: flex;
                align-items: center;
                margin: 0 15px;
                padding: 10px 15px;
                border-radius: 4px;
                transition: all .3s;

                &:hover {
                    background: lighten($fade-grey, 4%);
                }

                img {
                    display: block;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                }

                .meta {
                    margin-left: 12px;

                    span {
                        display: block;
                        line-height: 1.2;

                        &:first-child {
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 600;
                            font-size: .9rem;
                            color: $blue-grey;
                        }

                        &:nth-child(2) {
                            font-size: .95rem;
                            color: $muted-grey;
                        }
                    }
                }
            }
        }

        .logout-block {
            padding: 20px 30px;
            text-align: center;

            .button {
                min-width: 210px;
                font-size: .9rem;
                height: 44px;
                padding-bottom: 6px;
                font-weight: 500;
                color: $blue-grey;
            }
        }
    }
}

/* ==========================================================================
4. Mobile Nav
========================================================================== */

.mobile-nav {
    position: fixed;
    top: 0;
    left: 0;
    margin-left: 60px;
    width: 100%;
    max-width: calc(100% - 60px);
    height: 50px;
    background: $white;
    border-bottom: 1px solid $fade-grey;
    transform: translateY(0);
    transition: all .3s;
    z-index: 29;

    &.is-active {
        transform: translateY(59px);
    }

    &.is-fullwidth {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0 !important;
    }

    .mobile-nav-inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8px;

        .mobile-item {
            position: relative;
            height: 50px;
            width: 50px;
            display: flex;
            justify-content: center;
            align-items: center;

            i {
                font-size: 18px;
                color: $title-grey;
            }

            svg {
                height: 18px;
                width: 18px;
                stroke: $muted-grey;
            }

            img {
                display: block;
                height: 32px;
                width: 32px;
                border-radius: 50%;
            }

            .new-circle {
                width: 10px;
                height: 10px;
                right: 14px;
                top: 11px;
                position: absolute;
                border-radius: 50px;
                border: 2px solid $smoke-white;
                background-color: $primary;
            }
        }
    }
}

/* ==========================================================================
Search Results
========================================================================== */

.search-results {
    position: absolute;
    top: 46px;
    left: 0;
    width: 100%;
    max-height: 322px;
    overflow-y: auto;
    background: $white;
    opacity: 0;
    border: 1px solid darken($fade-grey, 3%);
    border-radius: 4px;
    pointer-events: none;
    transform: translateY(5px);
    box-shadow: $light-box-shadow;
    transition: all .3s;
    z-index: 5;

    &.is-active {
        opacity: 1;
        pointer-events: all;
        transform: translateY(0);
    }

    .placeholder-wrap {
        min-height: 285px;
        display: flex;
        align-items: center;
        justify-content: center;

        .placeholder-content {
            img {
                display: block;
                margin: 0 auto 16px auto;
                max-width: 160px;
            }

            h3 {
                font-family: 'Montserrat', sans-serif;
                font-weight: 600;
                color: $blue-grey;
                padding: 0;
            }

            p {
                color: $muted-grey;
                font-size: .95rem;
                max-width: 360px;
                margin: 0 auto;
            }
        }
    }

    .search-result {
        display: flex;
        align-items: center;
        padding: 12px 16px;
        border-bottom: 1px solid $fade-grey;
        transition: all .3s;

        &:last-child {
            border-bottom: none;
        }

        &:hover {
            background: lighten($fade-grey, 4%);
        }

        img {
            display: block;
            height: 38px;
            width: 38px;

            &.avatar {
                border-radius: 50%;
            }

            &.record {
                border-radius: 2px;
            }
        }

        .fake-avatar {
            height: 38px;
            width: 38px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 700;
            font-family: 'Montserrat', sans-serif;
            font-size: .8rem;
            color: $white;
        }

        .meta {
            margin-left: 12px;

            span {
                display: block;
                line-height: 1.2;

                &:first-child {
                    font-family: 'Montserrat', sans-serif;
                    font-weight: 500;
                    font-size: .9rem;
                    color: $blue-grey;
                }

                &:nth-child(2) {
                    font-size: .85rem;
                    color: $muted-grey;
                }
            }
        }
    }
}
