/*! _dashboard-layout.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Dashboard feed styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Layout
1. Login
=============================================================================
***/

/* ==========================================================================
0. Layout wrappers
========================================================================== */

body {
    min-height: 100vh;
}

//Setting up fluid transitions for navbar and dashboard wrapper
#dashboard-wrapper, .dashboard-outer, .dashboard-wrapper, .dashboard-nav {
    -webkit-transition: all .3s;
    transition: all .3s;
}

//Main Wrapper
.dashboard-outer {
    padding: 65px 40px 80px 40px;
    width: calc(100% - 80px);
    margin-left: 80px;

    &.is-docked {
        padding: 0;

        .dashboard-inner {
            max-width: 100% !important;
        }
    }

    .dashboard-inner {
        max-width: 1340px;
        margin: 0 auto;
    }
}

.is-inbox {
    min-height: 100vh;
    background: $white;
}

//Dashboard wrapper
.dashboard-wrapper {
    transform: translateY(0);
    transition: all .3s;

    &.is-pushed-nav-mobile {
        transform: translateY(65px);
    }

    &.is-kanban {
        min-height: unset !important;
    }

    &.is-inbox {
        padding: 0;
    }

    h3.popover-title {
        margin-top: 0 !important;
    }

    .dashboard-tiles {
        padding-top: 20px;

        .dashboard-tile {
            @extend .flex-card;

            overflow: visible;
            display: flex;
            align-items: center;
            padding: 16px;

            .icon-header i {
                position: absolute;
                top: -16px;
                left: 12px;
                padding: 22px;
                font-size: 24px;
                font-weight: normal;
                color: $white;
                border-radius: 6px;

                &.primary {
                    background-color: $primary;
                    box-shadow: $primary-box-shadow;
                }

                &.secondary {
                    background-color: $secondary;
                    box-shadow: $secondary-box-shadow;
                }

                &.accent {
                    background-color: $accent;
                    box-shadow: $accent-box-shadow;
                }
            }

            .tile-content {
                margin-left: 80px;

                h3 {
                    font-family: 'Nexa Bold', sans-serif;
                    font-size: 1.4rem;
                    color: $blue-grey;
                }

                p {
                    span {
                        &:first-child {
                            font-family: 'Montserrat', sans-serif;
                            font-weight: 700;
                            font-size: 1.6rem;
                            color: $blue-grey;
                        }

                        &:nth-child(2) {
                            color: $muted-grey;
                        }
                    }
                }
            }

            .view-all-button {
                position: absolute;
                bottom: -12px;
                right: 18px;
                height: 36px;
                width: 36px;
                border-radius: 50%;
                border: 1px solid darken($fade-grey, 4%);
                background: $white;
                box-shadow: $light-box-shadow;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all .3s;

                &:hover {
                    background: $secondary;
                    box-shadow: $secondary-box-shadow;

                    i {
                        color: $white;
                    }
                }

                i {
                    color: $muted-grey;
                    transition: color .3s;
                }
            }
        }
    }

    .dashboard-chart-card {
        @extend .flex-card;

        margin-bottom: 22px;
    }

    .map-card {
        @extend .flex-card;

        margin-bottom: 22px;

        svg {
            transform: scale(0.8);
        }
    }

    .members-card {
        @extend .flex-card;

        margin-bottom: 22px;

        .user-list {
            margin: 0 !important;
            padding: 0 0 16px 0;

            li {
                padding-right: 26px;
            }
        }
    }

    .tasks-card {
        @extend .flex-card;

        padding: 30px;
        text-align: center;
    }

    .achievement-card {
        @extend .flex-card;

        max-height: 270px;
    }

    .dash-card {
        @extend .flex-card;

        .card-heading {
            padding: 20px;
            color: $blue-grey;
            font-size: 1.2rem;
            font-weight: 600;
            font-family: 'Nexa Bold', sans-serif;
            color: $blue-grey;
            margin: 0 !important;

            &.has-icon {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .sl {
                    font-size: 1.1rem;
                }
            }

            &.is-bordered {
                border-bottom: 1px solid darken($fade-grey, 5%);
            }

            > span {
                font-family: 'Nexa Bold', sans-serif;
            }
        }
    }

    .peity-widget {
        @extend .flex-card;

        margin-bottom: 22px;
        padding: 30px;
        display: flex;
        align-items: center;

        svg {
            max-width: 40%;
        }

        .meta {
            margin-left: 24px;

            span {
                display: block;

                &:first-child {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1rem;
                    font-weight: 500;
                    text-transform: uppercase;
                    color: $muted-grey;
                }

                &:nth-child(2) {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 1.4rem;
                    font-weight: 600;
                    color: $blue-grey;
                }
            }
        }
    }

    //Flex card styles
    .flex-card {
        //squared
        &.is-squared {
            max-height: 270px;
        }

        //Widgets colored material headers
        .material-header {
            padding: 20px;
            margin: 0 20px;
            margin-top: -10px;
            border-radius: 4px;

            h3 {
                font-weight: 500;
                font-size: 1.2rem;
                font-family: 'Nexa Bold', sans-serif;
                color: $white;
                padding-bottom: 10px;
            }

            &.is-primary {
                background: $primary;
                box-shadow: $primary-box-shadow;
            }

            &.is-secondary {
                background: $secondary;
                box-shadow: $secondary-box-shadow;
            }

            &.is-gradient {
                background: #7F00FF;
                background: -webkit-linear-gradient(to right, #E100FF, #7F00FF);
                background: linear-gradient(to right, #E100FF, #7F00FF);
                box-shadow: $secondary-box-shadow;
            }
        }

        //Card heading
        .card-heading {
            padding: 20px;
            color: $blue-grey;
            font-size: 1.2rem;
            font-weight: 600;
            font-family: 'Nexa Bold', sans-serif;
            color: $blue-grey;
            margin: 0 !important;

            //with icon
            &.has-icon {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .sl {
                    font-size: 1.1rem;
                }
            }

            //with absolute positionning
            &.is-absolute {
                position: absolute;
                top: 10px;
                left: 10px;
            }

            &.is-bordered {
                border-bottom: 1px solid darken($fade-grey, 5%);
            }

            > span {
                font-family: 'Nexa Bold', sans-serif;
            }
        }
    }
}

/* ==========================================================================
1. Login
========================================================================== */

.login-form-wrapper {
    padding: 0 20px;

    .no-account, .forgot, .return {
        font-weight: 500;
        color: $blue-grey !important;
        font-size: .95rem;
    }
}

.auth-card {
    @extend .flex-card;

    overflow: visible;
    max-width: 480px;
    margin: 0 auto;
    padding: 40px;
    margin-bottom: 1.5rem;

    .auth-card-header {
        top: -2rem;
        margin-top: -40px;

        h2 {
            font-family: 'Pacifico';
            color: $white;
            font-size: 2.6rem;
        }
    }

    .control-material {
        margin: 2em 0;

        &:nth-child(2) {
            margin-top: 1em !important;
        }
    }

    .field {
        &:nth-child(2) {
            margin-top: 30px !important;
        }

        .control {
            position: relative;

            .input {
                height: 44px;
                padding-left: 44px;
                box-shadow: none !important;

                &:focus {
                    box-shadow: $light-box-shadow !important;
                    border-color: darken($fade-grey, 3%);

                    + .form-icon {
                        svg {
                            stroke: $primary;
                        }
                    }
                }
            }

            .form-icon {
                position: absolute;
                top: 0;
                left: 0;
                height: 44px;
                width: 44px;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    height: 18px;
                    width: 18px;
                    stroke: $placeholder;
                    transition: stroke .3s;
                }
            }
        }
    }

    .button-wrap {
        padding: 20px 0 10px 0;
        text-align: center;

        &.is-secondary {
            .button {
                background: $secondary;
                border: 1px solid $secondary;
                box-shadow: $secondary-box-shadow;
            }
        }

        .button {
            height: 44px;
            min-width: 220px;
            background: $primary;
            color: $white;
            font-weight: 500;
            border: 1px solid $primary;
            box-shadow: $primary-box-shadow;
        }
    }
}
