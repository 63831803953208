/*! _dashboard-deal.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Dashboard deal styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Header
1. Stage
2. Deal Subheader
3. Deal content
4. Media Queries
=============================================================================
***/

/* ==========================================================================
0. Header
========================================================================== */

//Wrapper
.deal-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    //Avatar
    .deal-image {
        display: block;
        height: 44px;
        width: 44px;
        border-radius: 50%;
        margin-right: 10px;
    }
    //Wrapper
    .inner-wrap {
        .deal-title {
            font-size: 1.6rem;
            font-family: 'Nexa Bold', sans-serif;
            color: $blue-grey;
        }
        //Meta
        .title-meta {
            display: flex;
            align-items: center;

            .meta {
                display: flex;
                align-items: center;
                margin-right: 6px;

                i {
                    font-size: 20px;
                    margin-right: 3px;
                    color: $muted-grey;
                }

                span {
                    display: block;
                    font-weight: 500;
                    color: $muted-grey;
                    font-size: .9rem;
                }
            }
        }
    }
    //Won and lost actions
    .actions {
        display: flex;
        margin-left: auto;

        .button {
            height: 40px !important;
            margin-left: 8px;
            min-width: 100px;

            i {
                padding-right: 0;
            }
        }
    }
}

/* ==========================================================================
1. Stage
========================================================================== */

//Stage
.stage-wrapper {
    position: relative;
    margin-top: 12px;
    background: $white;
    padding: 6px;
    border-radius: 100px;
    border: 1px solid darken($fade-grey, 4%);

    //Wrap
    .stages {
        position: absolute;
        top: 20px;
        left: 0;
        display: flex;
        width: 100%;
        padding: 0 6px;

        //Single step
        .stage {
            position: relative;
            width: 100%;
            display: flex;
            justify-content: flex-end;
            align-items: baseline;
            border-right: 1px solid $muted-grey;
            padding: 0 16px;
            min-height: 30px;

            &:last-child {
                visibility: hidden;
            }

            //Modifier
            &.is-done {
                span i {
                    display: inline-block;
                    color: $secondary;
                }
            }

            span {
                position: absolute;
                bottom: 0;
                font-size: .9rem;
                font-weight: 500;
                color: $blue-grey;
                right: 10px;
                display: block;

                i {
                    position: relative;
                    top: 1px;
                    display: none;
                    color: $secondary;
                    margin-right: 4px;
                }
            }
        }
    }

    //Stage inner
    .stage-inner {
        padding: 3px;
        background: lighten($fade-grey, 4%);
        border-radius: 100px;

        //Progress bar
        .progress {
            height: .45rem !important;

            &::-webkit-progress-value {
                background-color: $secondary;
                border-radius: 100px;
            }

            &::-moz-progress-bar {
                background-color: $secondary;
                border-radius: 100px;
            }

            &::-ms-fill {
                background-color: $secondary;
                border-radius: 100px;
            }
        }
    }
}

/* ==========================================================================
2. Deal Subheader
========================================================================== */

//Deal meta
.deal-meta {
    display: flex;
    align-items: center;
    margin-top: 50px;

    //Price
    .deal-price {
        display: flex;
        align-items: center;

        span {
            display: block;

            &:first-child {
                font-size: 1.4rem;
                font-weight: 700;
                color: $blue-grey;
            }

            &:nth-child(2) {
                margin-left: 16px;
                color: $muted-grey;
            }
        }
    }

    //Owner
    .deal-owner {
        display: flex;
        align-items: center;
        margin-left: auto;

        img {
            display: block;
            height: 38px;
            width: 38px;
            border-radius: 50%;
        }

        .owner-meta {
            margin-left: 12px;

            span {
                display: block;

                &:first-child {
                    font-size: .9rem;
                    font-weight: 500;
                    color: $blue-grey;
                }

                &:nth-child(2) {
                    font-size: .9rem;
                    color: $muted-grey;
                }
            }
        }

        //Dropdown menu
        .dropdown {
            margin-left: 12px;

            > .button i {
                font-size: 18px;
            }
        }
    }
}

/* ==========================================================================
3. Deal content
========================================================================== */

//Deal content wrapper
.deal-content {
    margin-top: 20px;
    padding-bottom: 60px;

    .column {
        &.is-spaced {
            margin-top: 20px;
        }
    }

    //Form settings
    form {
        .button-wrap {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-top: 20px;

            button {
                line-height: 0;
            }

            .button {
                min-width: 100px;
            }

            .control {
                width: 100%;
                margin-right: 30px;
            }
        }
    }

    //Deal cards (left side)
    .deal-card {
        @extend .flex-card;
        border-radius: 6px;
        border: 1px solid darken($fade-grey, 4%);
        padding: 16px;
        margin-bottom: 1.5rem;

        //Header
        .deal-card-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            //Togglable header
            &.is-toggle {
                cursor: pointer;

                //States
                &:hover {
                    .toggle-icon {
                        background: lighten($fade-grey, 4%);
                    }
                }

                &.is-active {
                    .toggle-icon {
                        transform: rotate(90deg);
                    }
                }
                //Icon
                .toggle-icon {
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all .3s;

                    i {
                        font-size: 18px;
                    }
                }
            }

            h3 {
                text-transform: uppercase;
                font-size: .8rem;
                color: $blue-grey;
                font-weight: 500;
            }
        }

        //inner content
        .deal-card-content {
            display: none;
            margin-top: 15px;

            //Placeholder
            .details-placeholder {
                span {
                    display: block;
                    font-size: .9rem;
                    font-weight: 500;
                    color: $blue-grey;
                }

                a {
                    margin-top: 4px;
                    display: flex;
                    align-items: center;
                    font-size: .9rem;
                    color: $muted-grey;
                    transition: all .3s;

                    &:hover {
                        color: $secondary;

                        i {
                            color: $secondary;
                        }
                    }

                    i {
                        position: relative;
                        top: 1px;
                        font-size: 16px;
                        margin-right: 3px;
                        transition: all .3s;
                    }
                }
            }

            //Contact
            .deal-contact {
                display: flex;
                align-items: center;
                margin-left: auto;

                img {
                    display: block;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                }

                .contact-meta {
                    margin-left: 12px;

                    span {
                        display: block;

                        &:first-child {
                            font-size: .9rem;
                            font-weight: 500;
                            color: $blue-grey;
                        }

                        &:nth-child(2) {
                            font-size: .9rem;
                            color: $muted-grey;
                        }
                    }
                }
            }

            //Additional info
            .info-block {
                display: flex;
                align-items: center;
                margin-left: auto;

                //Modifiers
                &.top-spaced {
                    margin-top: 12px;
                }

                &.is-add:hover {
                    .block-icon {
                        background: lighten($fade-grey, 4%);

                        i {
                            color: $muted-grey;
                        }

                        &:hover {
                            background: $secondary;
                            box-shadow: $secondary-box-shadow;
                            cursor: pointer;

                            i {
                                color: $smoke-white;
                            }
                        }
                    }
                }

                //Icon
                .block-icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 32px;
                    width: 32px;
                    border-radius: 50%;
                    transition: all .3s;

                    i {
                        font-size: 1.2rem;
                        color: $muted-grey;
                        transition: all .3s;
                    }
                }

                //Meta
                .block-meta {
                    margin-left: 12px;

                    span {
                        display: block;

                        &:first-child {
                            font-size: .9rem;
                            font-weight: 500;
                            color: $blue-grey;
                        }

                        &:nth-child(2) {
                            font-size: .9rem;
                            color: $muted-grey;

                            a {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }

    //Right side card actions
    .action-card {
        border-radius: 6px;
        border: 1px solid darken($fade-grey, 4%);

        //Header
        .action-card-header {
            padding: 16px;
            background: lighten($fade-grey, 2%);
            display: flex;
            align-items: center;

            //Single action
            .action {
                position: relative;
                margin: 0 12px;
                cursor: pointer;

                &.is-active {
                    i {
                        color: $blue-grey;
                    }

                    span {
                        color: $blue-grey;
                        font-weight: 500;
                    }

                    .arrow-down {
                        display: block;
                    }
                }

                i {
                    font-size: 14px;
                    color: $muted-grey;
                }

                span {
                    font-size: .9rem;
                    color: $muted-grey;
                }

                //Appended arrow
                .arrow-down {
                    display: none;
                    position: absolute;
                    bottom: -27px;
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    width: 0;
                    height: 0;
                    border-left: 12px solid transparent;
                    border-right: 12px solid transparent;
                    border-top: 12px solid lighten($fade-grey, 2%);
                }
            }
        }

        //Actions content
        .action-card-body {
            .action-wrap {
                padding: 16px;

                .event-form {
                    padding: 20px;
                }

                .action-title {
                    font-size: 1.1rem;
                    color: $blue-grey;
                    font-weight: 600;
                    margin-bottom: 20px;
                }

                .textarea {
                    box-shadow: none !important;
                    border-color: transparent;
                }

                .deal-field {
                    .control {
                        position: relative;

                        &.is-flex {
                            display: flex;
                            align-items: center;
                            min-height: 40px;

                            .custom-checkbox .toggler {
                                transform: scale(0.9);
                            }

                            .checkbox-label {
                                display: block;
                                font-size: .9rem;
                                color: $muted-grey;
                            }
                        }

                        input {
                            border: 1px solid darken($fade-grey, 4%);
                            border-radius: 4px;
                            height: 40px;
                            font-size: 1rem;
                            padding-left: 40px;
                            transition: all .3s;

                            &:focus {
                                + .form-icon i {
                                    color: $secondary;
                                }
                            }
                        }

                        .form-icon {
                            position: absolute;
                            top: 0;
                            left: 0;
                            height: 40px;
                            width: 40px;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            i {
                                color: $muted-grey;
                                font-size: 20px;
                                transition: all .3s;
                            }
                        }
                    }
                }
            }
        }
    }

    //Separator
    .timeline-separator {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0 30px 0;

        &.is-spaced {
            margin-top: 40px;
        }

        span {
            display: block;
            min-width: 110px;
            text-align: center;
            padding: 8px 16px;
            border-radius: 50px;
            color: $blue-grey;
            font-size: .9rem;
            font-weight: 500;
            background: $fade-grey;
        }
    }

    //Tabs
    .tabs {
        ul {
            border-bottom-color: transparent;
        }
    }

    .navtab-content {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .timeline-load {
        margin: 40px 0;
    }

    //Timeline
    .deal-timeline {
        ul {
            li {
                position: relative;
                display: flex;
                align-items: center;

                &:not(:last-child) {
                    padding-bottom: 20px;
                }

                &:last-child {
                    &:after {
                        height: 100vh;
                    }
                }

                &:after {
                    content: '';
                    position: absolute;
                    left: 19px;
                    top: 50%;
                    width: 1.6px;
                    height: 100%;
                    background: darken($fade-grey, 4%);
                }

                //Icon
                .timeline-icon {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 40px;
                    width: 40px;
                    min-width: 40px;
                    border-radius: 50%;
                    background: $white;
                    border: 1px solid darken($fade-grey, 4%);
                    z-index: 1;

                    .material-icons {
                        font-size: 18px;
                        color: $blue-grey;
                    }

                    .sl {
                        font-size: 16px;
                        color: $blue-grey;
                    }

                    img {
                        display: block;
                        height: 32px;
                        width: 32px;
                        min-width: 32px;
                        border-radius: 50%;
                    }
                }

                //Timeine box
                .timeline-item {
                    position: relative;
                    width: 100%;
                    background: $white;
                    border-radius: 6px;
                    border: 1px solid darken($fade-grey, 4%);
                    margin-left: 20px;

                    //Arrow settings
                    &:after, &:before {
                        right: 100%;
                        top: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                    }

                    &:after {
                        border-color: rgba(255, 255, 255, 0);
                        border-right-color: #fff;
                        border-width: 9px;
                        margin-top: -9px;
                    }

                    &:before {
                        border-color: rgba(237, 237, 237, 0);
                        border-right-color: darken($fade-grey, 4%);
                        border-width: 10px;
                        margin-top: -10px;
                    }

                    //Color modifier
                    &.is-note {
                        background: #fcfce5;
                        border-color: darken(#ffffd8, 25%);

                        &:after {
                            border-right-color: #fcfce5;
                        }

                        &:before {
                            border-right-color: darken(#ffffd8, 25%);
                        }
                    }

                    //Inner part
                    .item-body {
                        padding: 16px 20px;

                        h3 {
                            font-size: 1.1rem;
                            font-weight: 500;
                            color: $blue-grey;
                        }

                        .note-text {
                            color: $blue-grey;
                            margin-right: 30px;
                            margin-top: 6px;
                        }

                        .meta-list {
                            display: flex;
                            align-items: center;
                            margin-top: 6px;

                            .meta-list-item {
                                display: flex;
                                align-items: center;
                                margin-right: 6px;

                                img {
                                    display: block;
                                    height: 26px;
                                    width: 26px;
                                    min-width: 26px;
                                    border-radius: 50%;
                                    margin-right: 8px;
                                }

                                i {
                                    font-size: 16px;
                                    margin-right: 3px;
                                    color: $muted-grey;
                                }

                                span {
                                    display: block;
                                    font-weight: 500;
                                    color: $muted-grey;
                                    font-size: .9rem;

                                    &.is-thin {
                                        font-weight: 400;
                                    }
                                }
                            }
                        }

                        .document-item {
                            display: flex;
                            align-items: center;

                            img {
                                display: block;
                                height: 40px;
                                width: 40px;
                                min-width: 40px;
                                border-radius: 6px;
                            }

                            .document-meta {
                                margin-left: 12px;

                                span {
                                    display: block;

                                    a {
                                        font-weight: 500;
                                    }

                                    &:nth-child(2) {
                                        color: $muted-grey;
                                        font-size: .9rem;
                                    }
                                }
                            }
                        }
                    }

                    .item-footer {
                        padding: 16px 20px;
                        background: lighten($fade-grey, 2%);
                        border-top: 1px solid darken($fade-grey, 4%);

                        p {
                            font-size: .9rem;
                            font-weight: 500;
                            color: $blue-grey;
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
4. Media Queries
========================================================================== */

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width: 767px) {
    //Title
    .deal-title-wrapper {
        &.is-mobile {
            flex-direction: column;

            img {
                margin-right: 0;
            }

            .deal-title {
                text-align: center;
            }

            .actions {
                margin: 20px 0 0 0;
                justify-content: space-between;
                width: 100%;
            }
        }
    }
    //Stage
    .stage-wrapper {
        .stages {
            .stage {
                border-right-color: transparent;

                span {
                    display: none !important;
                }
            }
        }
    }
    //Meta
    .deal-meta {
        flex-direction: column;

        .deal-owner {
            margin: 10px 0 0 0;
            width: 100%;
        }

        .dropdown {
            margin-left: auto !important;
        }
    }
    //Action card
    .action-card {
        .action-card-header {
            padding: 10px !important;

            .action {
                i {
                    font-size: 11px !important;
                }

                span {
                    font-size: .8rem !important;
                }

                .arrow-down {
                    bottom: -22px !important;
                }
            }
        }

        .action-wrap {
            .button-wrap {
                .control {
                    visibility: hidden;
                }
            }
        }
    }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {}
