/*! _forms.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Form inputs styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Mixins
1. Form labels
2. Text inputs
3. Custom input
4. Textareas
5. Input wrappers
6. Material Inputs
7. Native select box
8. Autocomplete
9. jQuery datepicker
10. File inputs
11. Checkboxes and radios
12. Combo boxes
=============================================================================
***/

/* ==========================================================================
0. Mixins
========================================================================== */

//Vendor prefix Mixin
@mixin prefix($prop, $val) {
    @each $prefix in "-webkit-", "-moz-", "-ms-", "" {
        #{$prefix}#{$prop}: $val;
    }
}

/* ==========================================================================
1. Form labels
========================================================================== */

.form-label {
    padding-bottom: 5px;
    color: $muted-grey;
}

.grey-label-compact {
    font-weight: 500;
    font-size: .9rem;
    color: $muted-grey;
}

/* ==========================================================================
2. Text inputs
========================================================================== */

.input {
    color: $basaltic-grey;
    box-shadow: none;
    transition: all 0.8s;
    padding-bottom: 3px;
    border-color: $default-input;
    box-shadow: none;

    //rounded style
    &.rounded {
        border-radius: 100px;
    }

    //focus colors
    &.is-primary-focus:focus {
        border-color: $primary;

        ~ span.icon i {
            color: $primary;
        }
    }

    &.is-secondary-focus:focus {
        border-color: $secondary;

        ~ span.icon i {
            color: $secondary;
        }
    }

    &.is-accent-focus:focus {
        border-color: $accent;

        ~ span.icon i {
            color: $accent;
        }
    }

    &.is-grey-focus:focus {
        border-color: $title-grey;

        ~ span.icon i {
            color: $title-grey;
        }
    }

    //default
    &:focus, &:active {
        //border-color: $light-grey;
        //box-shadow: none !important;
    }
}

/* ==========================================================================
3. Custom input
========================================================================== */
.input.custom-input {
    height: 46px;
    line-height: 46px;
    padding-left: 50px !important;

    &:focus {
        border-color: #dbdbdb !important;
    }
}

/* ==========================================================================
4. Textareas
========================================================================== */
textarea.textarea {
    color: $basaltic-grey;
    box-shadow: none;
    transition: all 0.8s;

    //focus colors
    &:focus {
        border: 1px solid $fade-grey;
    }

    &.is-primary-focus:focus {
        border-color: $primary;

        ~ span.icon i {
            color: $primary;
        }
    }

    &.is-secondary-focus:focus {
        border-color: $secondary;

        ~ span.icon i {
            color: $secondary;
        }
    }

    &.is-accent-focus:focus {
        border-color: $accent;

        ~ span.icon i {
            color: $accent;
        }
    }

    &.is-grey-focus:focus {
        border-color: $title-grey;

        ~ span.icon i {
            color: $title-grey;
        }
    }

    &.is-default-focus:focus {
        border-color: $default-input;

        ~ span.icon i {
            color: $default-input;
        }
    }

    //growing textarea
    &.is-grow {
        min-height: 40px !important;
        height: 40px;
        resize: none;
        transition: all 0.5s;

        &:focus {
            height: 130px !important;
            border-color: $muted-grey;
        }
    }

    //embeded button inside textarea
    &.is-button {
        min-height: 40px !important;
        height: 40px !important;
        resize: none;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: none !important;
        transition: all 0.6s;

        &:hover {
            border-color: $default-input;
        }

        &:focus {
            height: 130px !important;
            padding-bottom: 40px !important;
            border-color: $default-input;
        }

        + .textarea-button {
            display: flex;
            justify-content: flex-end;
            position: relative;
            border: 1px solid $default-input;
            border-top: none !important;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;

            button {
                margin: 10px 7px 7px 7px;
            }
        }
    }
}

/* ==========================================================================
5. Input wrappers
========================================================================== */

.field {
    //loading state
    .control.is-loading:after {
        position: relative;
        top: 0.6rem;
        z-index: 1;
    }

    //sizes
    .control.is-small:after {
        height: 0.8rem;
        width: 0.8rem;
        position: relative;
        top: 0.4rem;
        z-index: 1;
    }

    .control.is-medium:after {
        height: 1.2rem;
        width: 1.2rem;
        position: relative;
        top: 0.8rem;
        z-index: 1;
    }

    .control.is-large:after {
        height: 1.5rem;
        width: 1.5rem;
        position: relative;
        top: 1rem;
        z-index: 1;
    }

    //with icons
    .control.has-icons-left .icon, .control.has-icons-right .icon {
        color: #dbdbdb;
        height: 2.25em;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 2.25em;
    }

    .control {
        &.has-icons-right, &.has-icons-left {
            .input ~ span i {
                transition: all .3s;
            }

            .input:focus {
                ~ span i {
                    color: $title-grey !important;
                }
            }
        }

        //validation
        &.required {
            position: relative;

            i {
                display: none;
                position: absolute;
                right: 10px;
                top: 8px;
            }

            //validation info
            small.validation-info {
                display: none;
                font-size: 0.8rem;
            }

            //success state
            &.has-success {
                input {
                    border: 1px solid $green;
                }

                small.validation-info.success {
                    color: $green;
                    display: block;
                }

                .success i {
                    display: block;
                    color: $green;
                }
            }

            //error state
            &.has-error {
                input {
                    border: 1px solid $red;
                }

                small.validation-info.error {
                    color: $red;
                    display: block;
                }

                .error i {
                    display: block;
                    color: $red;
                }
            }
        }
    }
}

/* ==========================================================================
6. Material Inputs
========================================================================== */

//Material control
.control-material {
    position: relative;
    margin-bottom: 3em;

    input, textarea {
        border-radius: 0 !important;
    }

    //primary colors
    &.is-primary {
        input.material-input:focus ~ label, input:valid ~ label, textarea:focus ~ label {
            color: $primary;
        }

        .bar:before, .bar:after {
            background: $primary;
        }

        &.has-icon input:focus ~ i {
            color: $primary !important;
        }
    }

    //secondary colors
    &.is-secondary {
        input.material-input:focus ~ label, input:valid ~ label, textarea:focus ~ label {
            color: $secondary;
        }

        .bar:before, .bar:after {
            background: $secondary;
        }

        &.has-icon input:focus ~ i {
            color: $secondary !important;
        }
    }

    //accent colors
    &.is-accent {
        input.material-input:focus ~ label, input:valid ~ label, textarea:focus ~ label {
            color: $accent;
        }

        .bar:before, .bar:after {
            background: $accent;
        }

        &.has-icon input:focus ~ i {
            color: $accent !important;
        }
    }

    //light colors
    &.is-light {
        input.material-input:focus ~ label, input:valid ~ label, textarea:focus ~ label {
            color: $white;
        }

        .bar:before, .bar:after {
            background: $white;
        }

        &.has-icon input:focus ~ i {
            color: $white !important;
        }
    }

    //baisc material styles
    input.material-input, textarea {
        width: 100% !important;
        font-size: 18px;
        padding: 0.625em  0.625em 0.625em 0.3125em;
        display: block;
        width: 18.75em;
        border: none;
        border-bottom: 1px solid $title-grey;
        background-color: transparent !important;

        &:focus {
            outline: none;
        }
    }

    //Floating label
    label {
        font-family: 'Arial';
        color: $placeholder;
        font-size: 15px;
        font-weight: normal;
        position: absolute;
        pointer-events: none;
        left: 0.3125em;
        top: 0.625em;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    input.material-input:focus ~ label, input:valid ~ label, textarea:focus ~ label {
        top: -20px;
        left: -2px;
        font-size: 12px;
    }

    //bottom bar
    .bar {
        position: relative;
        display: block;
    }

    .bar:before, .bar:after {
        content: '';
        height: 2px;
        width: 0;
        bottom: 0px;
        position: absolute;
        transition: 0.2s ease all;
        -moz-transition: 0.2s ease all;
        -webkit-transition: 0.2s ease all;
    }

    .bar:before {
        left: 50%;
    }

    .bar:after {
        right: 50%;
    }

    input.material-input:focus ~ .bar:before, input.material-input:focus ~ .bar:after, textarea:focus ~ .bar:before, textarea:focus ~ .bar:after {
        width: 50%;
    }

    //with icon
    &.has-icon {
        input {
            padding-right: 25px;
        }

        position: relative;

        i {
            position: absolute;
            right: 15px;
            top: 12px;
            color: $placeholder;
        }
    }

    //validation
    &.required {
        input {
            padding-right: 25px;
        }

        position: relative;

        i {
            position: absolute;
            right: 15px;
            top: 8px;
            color: $placeholder;
            display: none;
        }

        small.validation-info {
            display: none;
            font-size: 0.8rem;
        }

        //success state
        &.has-success {
            input {
                border-bottom-color: $green !important;
            }

            .bar:after, .bar:before {
                background: $green !important;
            }

            .success i {
                color: $green !important;
                display: block;
            }

            label {
                color: $green !important;
            }

            small.validation-info.success {
                color: $green;
                display: block;
            }
        }

        //error state
        &.has-error {
            input {
                border-bottom-color: $red !important;
            }

            .bar:after, .bar:before {
                background: $red !important;
            }

            .error i {
                color: $red !important;
                display: block;
            }

            label {
                color: $red !important;
            }

            small.validation-info.error {
                color: $red;
                display: block;
            }
        }
    }
}

/* ==========================================================================
7. Native select box
========================================================================== */

//base class
.select {
    &:after {
        border: 1px solid $primary;
        border-right: 0;
        border-top: 0;
    }

    &:hover {
        &:after {
            border: 1px solid darken($primary, 10%);
            border-right: 0;
            border-top: 0;
        }
    }

    select {
        &:active, &:focus {
            border-color: $primary;
        }
    }
}

//wrapper styles
.control.has-icons-left .select {
    //normal size
    select {
        padding-left: 2.5rem;
    }

    + .icon {
        top: 0.6rem;
        font-size: 1.2rem;
    }

    &.is-small + .icon {
        top: 0.4rem;
        font-size: 1rem;
    }

    //medium size
    &.is-medium {
        + .icon {
            top: 0.9rem;
            font-size: 1.5rem;
        }

        select {
            padding-left: 3rem;
        }
    }

    //large size
    &.is-large {
        + .icon {
            top: 1.2rem;
            font-size: 1.6rem;
        }

        select {
            padding-left: 3.3rem;
        }
    }
}

/* ==========================================================================
8. Autocomplete
========================================================================== */

//Base class
.autocpl {
    box-shadow: none !important;

    //container
    .easy-autocomplete-container {
        ul {
            padding: 10px !important;

            li {
                margin: 0 10px;
            }
        }
    }
}

//template-wrapper
.template-wrapper {
    display: flex !important;
    justify-content: flex-start;
    align-items: center;

    //avatar
    .autocpl-avatar {
        width: 36px !important;
        height: 36px !important;
        border-radius: 100px !important;
    }

    //text
    .entry-text {
        margin-left: 15px;

        span {
            font-size: 0.8rem;
            color: $title-grey;
        }
    }
}

/* ==========================================================================
9. jQuery datepicker
========================================================================== */

.datepicker-panel > ul > li.picked,
.datepicker-panel > ul > li.picked:hover {
    background-color: $accent !important;
}

/* ==========================================================================
10. File inputs
========================================================================== */

//wrappers
.file-input-wrapper .control {
    display: flex;
    justify-content: center;
    position: relative;

    //hide native input
    .inputfile {
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }

    //File input label
    .inputfile + label {
        max-width: 80%;
        font-size: 1.05rem;
        font-weight: 400;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        border-radius: 5px;
        padding: 0.625rem 1.45rem 0.8rem 1.25rem;

        @include prefix(transition, all 0.4s);

        i {
            position: relative;
            top: 4px;
            margin-right: 10px;
        }
    }

    .simple-file-input + label {
        color: $white;
        background-color: $accent;
    }

    .simple-file-input:focus + label,
    .simple-file-input.has-focus + label,
    .simple-file-input + label:hover {
        background-color: lighten($accent, 5%);
        outline: none;
        -moz-box-shadow: inset rgba(143, 160, 241, 0.2) 0 0 0 1px, rgba(213, 220, 247, 0.59) 0 10px 20px;
        -webkit-box-shadow: inset rgba(143, 160, 241, 0.2) 0 0 0 1px, rgba(213, 220, 247, 0.59) 0 10px 20px;
        box-shadow: inset rgba(143, 160, 241, 0.2) 0 0 0 1px, rgba(213, 220, 247, 0.59) 0 10px 20px;
    }
}

//Field style file input
.field-input-wrapper {
    box-sizing: border-box;

    //wrapper
    .field-input {
        display: flex;
        align-items: center;
        background: $smoke-white;
        border: 1px solid $fade-grey;
        padding: 16px;
        width: 450px;
        position: relative;
        border-radius: 3px;
    }

    //hide native input
    .field-input > [type='file'] {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        z-index: 10;
        cursor: pointer;
    }

    //button
    .field-input > .button {
        display: inline-block;
        cursor: pointer;
        padding: 8px 16px;
        border-radius: 2px;
        margin-right: 8px;
        line-height: 1;
    }

    //label
    .field-input > .label {
        color: $blue-grey;
        font-weight: 400;
        white-space: nowrap;
        opacity: .3;
    }

    .field-input.-chosen > .label {
        opacity: 1;
    }
}

/* ==========================================================================
11. Checkboxes and radios
========================================================================== */

//Material Checkbox wrapper
.label--checkbox {
    position: relative;
    margin: .5rem;
    font-family: Arial, sans-serif;
    line-height: 135% !important;
    cursor: pointer;
    transform: scale(0.7);

    span {
        position: relative;
        top: -7px;
        font-size: 14px;
        color: $blue-grey;
    }
}

//checkbox
.md-checkbox {
    position: relative;
    top: -0.375rem;
    margin: 0 1rem 0 0;
    cursor: pointer;
    transform: scale(1.8);

    &:before {
        -webkit-transition: all 0.3s ease-in-out;
        -moz-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        content: "";
        position: absolute;
        left: 0;
        z-index: 1;
        width: 0.9rem;
        height: 0.9rem;
        border: 1.5px solid #ccc;
    }

    &:checked:before {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        height: .5rem;
        border-color: $primary;
        border-top-style: none;
        border-right-style: none;
    }

    &:after {
        content: "";
        position: absolute;
        top: -0.125rem;
        left: 0;
        width: 1rem;
        height: 1rem;
        background: #fff;
        cursor: pointer;
    }
}

//Checked animation
@-webkit-keyframes slideUp {
    0% {
        -webkit-transform: translateY(6.25rem);
        transform: translateY(6.25rem);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-moz-keyframes slideUp {
    0% {
        -moz-transform: translateY(6.25rem);
        transform: translateY(6.25rem);
    }

    100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        -webkit-transform: translateY(6.25rem);
        transform: translateY(6.25rem);
    }

    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

//mobile adjustments
@media (min-width: 769px) {
    input.md-checkbox:focus {
        border-bottom-color: transparent !important;
        -webkit-box-shadow: none !important;
        box-shadow: none !important;
        outline: none !important;
        outline-offset: 0 !important;
        background: #fff !important;
    }
}

//Basic Checkbox and radio
%controller {
    position: relative;
    cursor: pointer;
    padding: 1em;

    &::selection {
        background: transparent;
    }

    //d-checkbox, b-radio
    input.d-checkbox + span, input.b-radio + span {
        background: $white;
        content: "";
        display: inline-block;
        margin: 0 .5em 0 0;
        padding: 0;
        border: 1px solid $muted-grey;
        vertical-align: middle;
        width: 2em;
        height: 2em;

        &::after {
            content: "";
            display: block;
            transform: scale(0);
            transition: transform .2s;
        }
    }

    //checked state
    input.d-checkbox:checked + span::after, input.b-radio:checked + span::after {
        transform: scale(1);
    }

    //hide native input
    input.d-checkbox, input.b-radio {
        position: absolute;
        cursor: pointer;
        opacity: 0;
    }
}

//checkbox style
.checkbox-wrap {
    @extend %controller;

    //check symbol
    input + span {
        border-radius: 2px;

        &::after {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwb2x5Z29uIHBvaW50cz0iMTMuNzA3LDMyLjI5MyAxMi4yOTMsMzMuODU0IDI0LjI5Myw0NiAyNS43MDcsNDYgNDkuNzA3LDIxLjg1NCA0OC4yOTMsMjAuMzY2IDI1LDQzLjYyMyAiLz48L2c+PC9zdmc+) no-repeat center;
            background-size: contain;
            width: 1.9em;
            height: 1.9em;
        }
    }

    //check symbol medium
    &.is-medium input + span {
        width: 1.4em;
        height: 1.4em;

        &::after {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwb2x5Z29uIHBvaW50cz0iMTMuNzA3LDMyLjI5MyAxMi4yOTMsMzMuODU0IDI0LjI5Myw0NiAyNS43MDcsNDYgNDkuNzA3LDIxLjg1NCA0OC4yOTMsMjAuMzY2IDI1LDQzLjYyMyAiLz48L2c+PC9zdmc+) no-repeat center;
            background-size: contain;
            width: 1.3em;
            height: 1.3em;
        }
    }

    //check symbol small
    &.is-small input + span {
        width: 1em;
        height: 1em;

        &::after {
            background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iTGF5ZXJfMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2NHB4IiBoZWlnaHQ9IjY0cHgiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgNjQgNjQiIHhtbDpzcGFjZT0icHJlc2VydmUiPjxnPjxwb2x5Z29uIHBvaW50cz0iMTMuNzA3LDMyLjI5MyAxMi4yOTMsMzMuODU0IDI0LjI5Myw0NiAyNS43MDcsNDYgNDkuNzA3LDIxLjg1NCA0OC4yOTMsMjAuMzY2IDI1LDQzLjYyMyAiLz48L2c+PC9zdmc+) no-repeat center;
            background-size: contain;
            width: 0.9em;
            height: 0.9em;
        }
    }
}

//Radio group style
.radio-wrap {
    @extend %controller;

    //base
    input + span {
        border-radius: 1000px;

        &::after {
            border-radius: 1000px;
            margin: .55em;
            width: .75em;
            height: .75em;
        }
    }

    //checked state
    input:checked + span::after {
        background: $blue-grey;
    }

    //sizes
    &.is-small span {
        transform: scale(0.6) !important;

        &::after {
            margin: .54em !important;
        }
    }

    &.is-medium span {
        transform: scale(0.8) !important;

        &::after {
            margin: .58em !important;
        }
    }

    //colors
    &.is-primary {
        input:checked + span::after {
            background: $primary;
        }
    }

    &.is-secondary {
        input:checked + span::after {
            background: $secondary;
        }
    }

    &.is-accent {
        input:checked + span::after {
            background: $accent;
        }
    }
}

/* ==========================================================================
12. Combo boxes
========================================================================== */

//Wrapper
.is-combo {
    margin-bottom: 20px;

    //Label
    .combo-label {
        font-weight: 500;
        font-size: .9rem;
        color: $muted-grey;
    }

    //Combo box types
    .combo-box, .image-combo-box, .stacked-combo-box, .user-combo-box {
        position: relative;
        background: $white;
        border: 1px solid darken($fade-grey, 4%);
        border-radius: 4px;
        height: 40px;
        margin-top: 4px;
        cursor: pointer;
        transition: all .3s;

        &.has-rounded-images {
            .combo-item img, .item-icon img {
                border-radius: 50%;
            }
        }

        //Combo box inner
        .box-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;

            //Selected item
            .combo-item {
                display: flex;
                align-items: center;
                height: 40px;
                padding-left: 8px;

                //icon
                i {
                    color: $secondary;
                    font-size: 20px;
                    margin-right: 8px;
                }

                .fa {
                    position: relative;
                    top: 1px;
                    font-size: 14px;

                    &.is-green {
                        color: $green;
                    }

                    &.is-blue {
                        color: $blue;
                    }

                    &.is-orange {
                        color: $orange;
                    }

                    &.is-red {
                        color: $red;
                    }

                    &.is-secondary {
                        color: $secondary;
                    }
                }

                //image
                img {
                    display: block;
                    height: 26px;
                    width: 26px;

                    //border-radius: 50%;
                    object-fit: contain;
                    margin-right: 8px;

                    //Modifier for stacked mode
                    &.is-stacked {
                        height: 26px;
                        width: 26px;
                        border: 2px solid $white;
                        margin-right: 0 !important;

                        &:not(:first-child) {
                            $var: 9;

                            @for $i from 1 through 5 {
                                &:nth-child(#{$i}) {
                                    margin-left: -#{$var}px;
                                }
                            }
                        }
                    }
                }

                .avatar-container {
                    position: relative;
                    display: block;
                    height: 26px;
                    width: 26px;

                    .avatar {
                        display: block;
                        height: 26px;
                        width: 26px;
                        border-radius: 50%;
                    }

                    .badge {
                        position: absolute;
                        bottom: 0;
                        right: -6px;
                        height: 16px;
                        width: 16px;
                        border-radius: 50%;
                        border: 2px solid $white;
                        margin: 0;
                    }
                }

                //Text
                span {
                    display: block;
                    color: $blue-grey;
                    font-size: .9rem;
                    font-weight: 500;
                    margin-left: 8px;
                }
            }
        }

        //Box caret
        .box-chevron {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 40px;

            i {
                font-size: 18px;
                stroke: $blue-grey;
                transform: rotate(0);
                transition: all .3s;
            }

            svg {
                height: 18px;
                width: 18px;
                stroke: $blue-grey;
                transform: rotate(0);
                transition: all .3s;
            }
        }

        //Combo dropdown
        .box-dropdown {
            position: absolute;
            top: 32px;
            left: 0;
            width: 100%;
            z-index: 50;
            opacity: 0;
            transform: translateY(20px);
            pointer-events: none;
            transition: all .3s;

            //Modifier
            &.is-dropup {
                top: -165px;
                height: 148px;

                .dropdown-inner {
                    overflow-y: auto;
                }
            }

            //Dropdown inner
            .dropdown-inner {
                position: relative;
                width: 100%;
                height: 100%;
                background: $white;
                border: 1px solid darken($fade-grey, 4%);
                border-radius: 4px;
                margin-top: 10px;
                max-height: 190px;
                overflow-y: auto;

                //List
                ul {
                    padding: 10px 0;

                    //List item
                    li {
                        padding: 6px 8px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;

                        //Icon
                        .item-icon {
                            position: relative;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 30px;
                            width: 30px;
                            margin-right: 8px;

                            i {
                                color: $muted-grey;
                                font-size: 20px;
                            }

                            .fa {
                                font-size: 14px;
                                position: relative;
                                top: 1px;

                                &.is-green {
                                    color: $green;
                                }

                                &.is-blue {
                                    color: $blue;
                                }

                                &.is-orange {
                                    color: $orange;
                                }

                                &.is-red {
                                    color: $red;
                                }

                                &.is-secondary {
                                    color: $secondary;
                                }
                            }

                            //Image
                            img {
                                display: block;
                                height: 22px;
                                width: 22px;

                                // border-radius: 50%;
                                object-fit: contain;
                                transition: opacity .3s;

                                &.faded-img {
                                    opacity: .4;
                                }
                            }

                            .avatar {
                                display: block;
                                height: 22px;
                                width: 22px;
                                border-radius: 50%;
                            }

                            .badge {
                                position: absolute;
                                bottom: 0;
                                right: 0;
                                height: 12px;
                                width: 12px;
                                border-radius: 50%;
                                border: 2px solid $white;
                            }
                        }

                        //Checkmark
                        .checkmark {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 16px;
                            width: 16px;
                            margin-right: 8px;
                            margin-left: auto;
                            opacity: 0;
                            transition: all .3s;

                            svg {
                                stroke: $secondary;
                                stroke-width: 3px;
                                height: 16px;
                                width: 16px;
                            }

                            i {
                                font-size: 16px;
                                color: $secondary;
                            }
                        }

                        span {
                            display: block;
                            color: $muted-grey;
                            font-size: .9rem;
                            font-weight: 500;
                        }

                        &:hover {
                            background: lighten($fade-grey, 2%);
                        }

                        //Active state
                        &.is-active {
                            .item-icon {
                                i {
                                    color: $secondary;
                                }

                                .faded-img {
                                    opacity: 1;
                                }
                            }

                            span {
                                color: $blue-grey;
                            }

                            .checkmark {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        //Combo box active state
        &.is-active {
            border-color: $secondary;

            .box-chevron svg, .box-chevron i {
                transform: rotate(180deg);
            }

            .box-dropdown {
                opacity: 1;
                transform: translateY(0);
                pointer-events: all;
            }
        }
    }

    //Help text
    .help-text {
        font-size: .75rem;
        color: $muted-grey;
    }

    //Big combo box with description
    .big-combo-box {
        position: relative;
        background: $white;
        border: 1px solid darken($fade-grey, 4%);
        border-radius: 4px;
        height: 58px;
        margin-top: 4px;
        cursor: pointer;
        transition: all .3s;

        //Inner
        .box-inner {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 100%;

            //Selected item
            .combo-item {
                display: flex;
                align-items: center;
                height: 58px;
                padding-left: 8px;

                i {
                    color: $secondary;
                    font-size: 1.6rem;
                }

                //Text
                .combo-text {
                    margin-left: 16px;

                    span {
                        display: block;

                        &:first-child {
                            color: $blue-grey;
                            font-size: .9rem;
                            font-weight: 500;
                        }

                        &:nth-child(2) {
                            color: $muted-grey;
                            font-size: .8rem;
                            max-width: 330px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }
                }
            }
        }

        //Caret
        .box-chevron {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 58px;
            width: 58px;

            svg {
                height: 18px;
                width: 18px;
                stroke: $blue-grey;
                transform: rotate(0);
                transition: all .3s;
            }
        }

        //Combo box dropdown
        .box-dropdown {
            position: absolute;
            top: 54px;
            left: 0;
            width: 100%;
            z-index: 50;
            opacity: 0;
            transform: translateY(20px);
            pointer-events: none;
            transition: all .3s;

            //Dropdown inner
            .dropdown-inner {
                position: relative;
                width: 100%;
                height: 100%;
                background: $white;
                border: 1px solid darken($fade-grey, 4%);
                border-radius: 4px;
                margin-top: 10px;
                max-height: 190px;
                overflow-y: auto;
                overflow-x: hidden;

                //List
                ul {
                    padding: 10px 0;

                    //List item
                    li {
                        padding: 6px 8px;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        width: 100%;

                        //Icon
                        .item-icon {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 30px;
                            width: 30px;
                            min-height: 30px;
                            min-width: 30px;
                            margin-right: 16px;

                            i {
                                color: $muted-grey;
                                font-size: 1.6rem;
                            }
                        }

                        //Content
                        .item-name {
                            span {
                                display: block;

                                &:first-child {
                                    color: $blue-grey;
                                    font-size: .9rem;
                                    font-weight: 500;
                                }

                                &:nth-child(2) {
                                    color: $muted-grey;
                                    font-size: .8rem;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }
                            }
                        }

                        &:hover {
                            background: lighten($fade-grey, 2%);
                        }

                        //Active state
                        &.is-active {
                            .item-icon i {
                                color: $secondary;
                            }

                            span {
                                color: $blue-grey;
                            }
                        }
                    }
                }
            }
        }

        //Big combo box active state
        &.is-active {
            border-color: $secondary;

            .box-chevron svg {
                transform: rotate(180deg);
            }

            .box-dropdown {
                opacity: 1;
                transform: translateY(0);
                pointer-events: all;
            }
        }
    }
}

/* ==========================================================================
Custom checkbox
========================================================================== */

.custom-checkbox {
    width: 65px;
    display: block;
    position: relative;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transform: scale(0.9);

    //Checkbox
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;

        //Checbox states
        &:checked ~ .toggler {
            border-color: $secondary;

            .active, .inactive {
                transform: translateX(100%) rotate(360deg);
            }

            .active {
                opacity: 1;
            }

            .inactive {
                opacity: 0;
            }
        }
    }

    //Toggler handle
    .toggler {
        position: relative;
        display: block;
        height: 34px;
        width: 61px;
        border: 1.8px solid $placeholder;
        border-radius: 100px;
        transition: all .3s;

        //States
        .active, .inactive {
            position: absolute;
            top: 2px;
            left: 2px;
            height: 26px;
            width: 26px;
            border-radius: 50%;
            background: black;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translateX(0) rotate(0);
            transition: all .3s ease;

            svg {
                stroke: $white;
                height: 14px;
                width: 14px;
                stroke-width: 3px;
            }

            i {
                font-size: 14px;
                font-weight: 600;
                color: $white;
            }
        }

        .inactive {
            background: $placeholder;
            border-color: $placeholder;
            opacity: 1;
            z-index: 1;
        }

        .active {
            background: $secondary;
            border-color: $secondary;
            opacity: 0;
            z-index: 0;
        }
    }
}
