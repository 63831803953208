/*! _dashboard-forum.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Dashboard forum styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Home
1. Forum search
2. Main Container
3. Inner Container
4. Topics
5. Topic
6. Reply box
7. Media Queries
=============================================================================
***/

/* ==========================================================================
0. Home
========================================================================== */

//Wrapper
.forum-title-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    //Avatar
    .forum-image {
        display: block;
        height: 44px;
        width: 44px;
        border-radius: 50%;
        margin-right: 10px;
    }

    //Inner title section
    .inner-wrap {
        .forum-title {
            font-size: 1.6rem;
            font-family: 'Nexa Bold', sans-serif;
            color: $blue-grey;
            max-width: 380px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .title-meta {
            display: flex;
            align-items: center;

            .meta {
                display: flex;
                align-items: center;
                margin-right: 6px;

                i {
                    font-size: 20px;
                    margin-right: 3px;
                    color: $muted-grey;

                    &.is-breadcrumb {
                        font-size: 18px;
                        position: relative;
                        top: 2px;
                    }
                }

                span, a {
                    display: block;
                    font-weight: 500;
                    color: $muted-grey;
                    font-size: .9rem;
                }

                a {
                    transition: all .3s;

                    &:hover {
                        color: $secondary;
                    }
                }
            }
        }
    }

    //Actions
    .actions {
        display: flex;
        margin-left: auto;

        .forum-back {
            height: 40px;
            width: 40px;
            min-width: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid darken($fade-grey, 4%);
            background: $white;
            transition: all .3s;
            cursor: pointer;

            &:hover {
                transform: rotate(360deg);
                border-color: $secondary;

                i {
                    color: $secondary;

                    &:first-child {
                        display: none;
                    }

                    &:nth-child(2) {
                        display: block;
                    }
                }
            }

            i {
                font-size: 18px;
                color: $placeholder;

                &:first-child {
                    display: block;
                }

                &:nth-child(2) {
                    display: none;
                }
            }
        }

        .button {
            height: 40px !important;
            margin-left: 8px;
            min-width: 100px;

            &.is-rounded {
                min-width: 120px;
            }

            i {
                padding-right: 0;
            }
        }
    }
}

/* ==========================================================================
1. Forum search
========================================================================== */

.forum-search {
    margin: 30px auto;
    position: relative;
    transition: all .3s;
    width: 100%;
    max-width: 600px;

    .input {
        height: 50px;
        padding-left: 50px;
        font-size: 1.2rem;
        background: lighten($fade-grey, 6%);
        border-color: darken($fade-grey, 1%);
        transition: all .3s;

        &:focus {
            background: $white;
            border-color: darken($fade-grey, 3%);
            box-shadow: $light-box-shadow !important;

            + .search-icon i {
                color: $secondary;
            }
        }
    }

    .search-icon {
        position: absolute;
        top: 0;
        left: 0;
        height: 50px;
        width: 50px;
        display: flex;
        justify-content: center;
        align-items: center;

        i {
            font-size: 18px;
            color: $placeholder;
            transition: color .3s;
        }
    }
}

/* ==========================================================================
2. Main Container
========================================================================== */

.forum-wrap {
    padding: 10px;
    background: $white;
    border: 1px solid darken($fade-grey, 3%);
    border-radius: 8px;
    margin-top: 20px;

    &:last-child {
        margin-bottom: 60px !important;
    }

    .latest-posts-wrap {
        display: none;
    }

    //Bottom togglable container section
    .latest-posts {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 10px 10px 10px;

        //Avatars
        .avatars {
            display: flex;
            align-items: center;

            .avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 38px;
                width: 38px;
                min-width: 38px;
                border-radius: 50%;
                border: 3px solid $white;
                background: $secondary;

                span {
                    position: relative;
                    left: -2px;
                    display: block;
                    font-weight: 600;
                    font-size: 1rem;
                    color: $smoke-white;
                }

                &:not(:first-child) {
                    margin-left: -12px;
                }
            }
        }

        //Meta
        .latest-meta {
            margin-left: 10px;

            span {
                display: block;

                &:first-child {
                    position: relative;
                    font-size: 1rem;
                    font-weight: 400;
                    color: $blue-grey;
                    width: auto;

                    a {
                        font-weight: 500;
                    }
                }

                &:nth-child(2) {
                    color: $muted-grey;
                }
            }
        }

        //Statistics
        .forum-stats {
            margin-left: auto;
            display: flex;
            align-items: center;

            .stat {
                display: flex;
                align-items: center;
                margin-left: 20px;

                i {
                    font-size: 18px;
                    margin-right: 6px;
                    color: $placeholder;
                }

                span {
                    display: block;
                    font-weight: 400;
                    color: $muted-grey;
                }
            }
        }
    }

    //Channnel moderators
    .channel-moderators {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 0;

        .avatars {
            display: flex;
            align-items: center;

            .avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 38px;
                width: 38px;
                min-width: 38px;
                border-radius: 50%;
                border: 3px solid $white;
                background: $secondary;

                span {
                    position: relative;
                    left: -2px;
                    display: block;
                    font-weight: 600;
                    font-size: 1rem;
                    color: $smoke-white;
                }

                &:not(:first-child) {
                    margin-left: -12px;
                }
            }
        }

        .moderators-meta {
            margin-left: 10px;

            span {
                display: block;

                &:first-child {
                    position: relative;
                    font-size: 1rem;
                    font-weight: 400;
                    color: $blue-grey;
                    width: auto;

                    a {
                        font-weight: 500;
                    }
                }

                &:nth-child(2) {
                    color: $muted-grey;
                }
            }
        }
    }

    //topic stats
    .topic-stats {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 10px 0;

        .stat-block {
            display: flex;
            align-items: center;
            margin-right: 40px;

            .avatar {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 34px;
                width: 34px;
                min-width: 34px;
                border-radius: 50%;
                background: $white;

                &.is-icon {
                    border-radius: 6px;
                }

                span {
                    position: relative;
                    left: -2px;
                    display: block;
                    font-weight: 600;
                    font-size: 1rem;
                    color: $smoke-white;
                }

                i {
                    font-size: 16px;
                    color: $blue-grey;
                }
            }

            .stat-meta {
                margin-left: 6px;

                span {
                    display: block;
                    font-size: .9rem;

                    &:first-child {
                        position: relative;
                        font-weight: 500;
                        color: $blue-grey;
                    }

                    &:nth-child(2) {
                        color: $muted-grey;
                    }
                }
            }
        }

        //Topic participants
        .participants {
            display: flex;
            align-items: center;
            margin-left: auto;

            .participant {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 30px;
                width: 30px;
                min-width: 30px;
                border-radius: 50%;
                border: 2px solid lighten($fade-grey, 2%);
                background: $secondary;

                span {
                    position: relative;
                    left: -2px;
                    display: block;
                    font-weight: 600;
                    font-size: .8rem;
                    color: $smoke-white;
                }

                &:not(:first-child) {
                    margin-left: -8px;
                }
            }
        }
    }
}

/* ==========================================================================
3. Inner Container
========================================================================== */

//Inner container
.forum-container {
    position: relative;
    padding: 20px;
    background: lighten($fade-grey, 2%);
    border: 1px solid darken($fade-grey, 5%);
    border-radius: 6px;

    //Bottom toggle button in home
    .toggle-button {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        margin: 0 auto;
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: $white;
        border: 1px solid darken($fade-grey, 5%);
        transition: all .3s;
        transform: rotate(0);
        cursor: pointer;

        //Modifier
        &.is-active {
            transform: rotate(180deg);

            i {
                color: $blue-grey;
            }
        }

        i {
            position: relative;
            top: 1px;
            font-size: 18px;
            color: $placeholder;
        }
    }

    //Channel title
    .channel-heading {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        h3 {
            font-size: 1.2rem;
            color: $muted-grey;
            font-family: 'Nexa Bold', sans-serif;
        }

        .dropdown {
            margin-left: auto;

            .button {
                &:hover {
                    background: $smoke-white !important;
                }
            }
        }
    }

    //Channel box
    .forum-channel {
        display: flex;
        align-items: center;
        width: 100%;
        background: $white;
        border: 1px solid darken($fade-grey, 5%);
        border-radius: 6px;
        padding: 20px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }

        &:hover {
            box-shadow: $light-box-shadow;
            border-color: $secondary;

            .channel-icon {
                background: $secondary;
                box-shadow: $secondary-box-shadow;

                i {
                    color: $smoke-white;
                }
            }
        }

        .channel-icon {
            position: relative;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all .3s;

            i {
                font-size: 2rem;
            }

            .new-indicator {
                position: absolute;
                top: -2px;
                right: -6px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 22px;
                min-width: 22px;
                border: 2px solid $white;
                border-radius: 50%;
                background: $primary;

                span {
                    position: relative;
                    display: block;
                    font-size: .75rem;
                    font-weight: 600;
                    line-height: 0;
                    color: $white;
                }
            }
        }

        .channel-meta {
            margin-left: 20px;

            span {
                display: block;

                &:first-child {
                    position: relative;
                    font-size: 1.1rem;
                    font-weight: 600;
                    color: $blue-grey;
                    width: auto;
                }

                &:nth-child(2) {
                    color: $muted-grey;
                }
            }
        }

        .channel-topics {
            margin-left: auto;
            margin-right: 20px;
            text-align: center;

            span {
                display: block;

                &:first-child {
                    font-size: .9rem;
                    font-weight: 500;
                    color: $blue-grey;
                }

                &:nth-child(2) {
                    color: $muted-grey;
                    font-weight: 500;
                }
            }
        }

        .top-contributors {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            img {
                display: block;
                height: 38px;
                width: 38px;
                min-width: 38px;
                border-radius: 50%;
                border: 3px solid $white;

                &:not(:first-child) {
                    margin-left: -10px;
                }
            }
        }
    }

    //Global forum pagination
    .pagination {
        list-style-type: none;
        display: flex;
        justify-content: center;
        align-items: center;

        li {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 5px;
            width: 34px;
            height: 34px;
            border-radius: 50%;
            border: 1px solid darken($fade-grey, 4%);
            background: $white;
            color: $blue-grey;
            transition: all .3s;
            cursor: pointer;

            &:hover {
                border-color: lighten($secondary, 10%);
                background: lighten($secondary, 10%);
                color: $white;
                box-shadow: 0px 5px 43px rgba(0, 0, 0, 0.25) !important;

                i {
                    color: $smoke-white;
                }
            }

            &.is-active {
                background: $secondary;
                opacity: 1;
                color: $white;
            }

            span {
                position: relative;
                top: -1px;
                font-size: .9rem;
                font-weight: 600;
            }

            i {
                font-size: 16px;
                font-weight: 600;
                color: $blue-grey;
            }
        }
    }
}

/* ==========================================================================
4. Topics
========================================================================== */

//Topic list
.topic-list {
    margin: 20px 0;

    //List divider
    .divider-wrap {
        margin: 20px 0;

        .divider {
            display: flex;
            align-items: center;
            margin: 1em -1em;
            color: $muted-grey;
            text-transform: uppercase;
            font-size: .9rem;
            font-weight: 500;

            &:before,
            &:after {
                content: "";
                flex: 1;
                height: 1px;
                margin: 0 1em;
                background: lighten($placeholder, 5%);
            }
        }
    }

    //Topic box
    .topic-card {
        display: flex;
        align-items: center;
        width: 100%;
        background: $white;
        border: 1px solid darken($fade-grey, 5%);
        border-radius: 8px;
        padding: 16px;
        transition: all .3s;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        //Color modifier
        &.is-sticky {
            background: #f7faff;
            border-color: #c1d9ff;

            .tags .tag {
                background: $white;
            }
        }

        //Owner
        .topic-owner {
            position: relative;
            height: 48px;
            width: 48px;
            min-width: 48px;

            .avatar {
                display: block;
                border-radius: 50%;
            }

            .badge {
                position: absolute;
                bottom: 0;
                right: -6px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 20px;
                width: 20px;
                border: 2px solid $white;
                border-radius: 50%;
                background: $orange;

                span {
                    display: block;
                }

                i {
                    font-size: 8px;
                    font-weight: 600;
                    color: $white;
                }
            }
        }

        //Meta
        .topic-meta {
            margin-left: 16px;

            .topic-title {
                position: relative;
                display: block;
                font-size: 1.1rem;
                font-weight: 500;
                color: $blue-grey;
                transition: color .3s;
                margin-bottom: 4px;

                &:hover {
                    color: $secondary;
                }
            }

            .flex-block {
                display: flex;
                align-items: center;
            }

            span {
                color: $muted-grey;
                font-size: .9rem;

                a {
                    font-weight: 500;
                }
            }

            .tags {
                margin-left: 8px;

                .tag {
                    color: $blue-grey;
                    font-size: .85rem;
                    border-radius: 100px;
                }
            }
        }

        //Actions
        .topic-actions {
            margin-left: auto;
            display: flex;
            align-items: center;

            .tag {
                font-weight: 500;
                margin-right: 20px;
            }

            .im {
                font-size: 1.8rem;
                color: $placeholder;
                margin-right: 20px;
            }

            .topic-replies {
                margin-right: 20px;
                text-align: center;

                span {
                    display: block;

                    &:first-child {
                        font-size: .9rem;
                        font-weight: 500;
                        color: $blue-grey;
                    }

                    &:nth-child(2) {
                        color: $muted-grey;
                        font-weight: 500;
                    }
                }
            }

            //Last reply
            .last-reply {
                display: flex;
                align-items: center;

                img {
                    display: block;
                    height: 26px;
                    width: 26px;
                    border-radius: 50%;
                }

                .last-reply-meta {
                    margin-left: 10px;
                    min-width: 90px;

                    span {
                        display: block;

                        &:first-child {
                            font-size: .9rem;
                            font-weight: 500;
                            color: $blue-grey;
                        }

                        &:nth-child(2) {
                            font-size: .9rem;
                            color: $muted-grey;

                            a {
                                font-size: .9rem;
                                color: $secondary;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .dropdown {
                margin-left: 30px;
            }
        }
    }
}

/* ==========================================================================
5. Topic
========================================================================== */

.topic-wrapper {
    width: 100%;
    max-width: 1040px;
    margin: 0 auto;
}

//Wrapper
.full-topic {
    width: 100%;
    background: $white;
    border: 1px solid darken($fade-grey, 5%);
    border-radius: 8px;
    padding: 16px;
    margin: 20px 0;

    //single topic post
    .post {
        display: flex;
        align-items: stretch;
        padding: 20px 0;

        &:not(:last-child) {
            border-bottom: 1px solid darken($fade-grey, 1%);
        }

        //Meta
        .post-meta {
            .post-owner {
                position: relative;
                height: 48px;
                width: 48px;
                min-width: 48px;

                .avatar {
                    display: block;
                    border-radius: 50%;
                }

                .badge {
                    position: absolute;
                    bottom: 0;
                    right: -6px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 20px;
                    width: 20px;
                    border: 2px solid $white;
                    border-radius: 50%;
                    background: $orange;

                    span {
                        display: block;
                    }

                    i {
                        font-size: 8px;
                        font-weight: 600;
                        color: $white;
                    }
                }
            }
        }

        //Content
        .post-content {
            margin-left: 20px;
            width: 100%;

            iframe {
                max-height: 160px;
            }

            .top-wrap {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .name-wrap {
                    .name {
                        font-family: 'Nexa Bold', sans-serif;
                        font-weight: 500;
                        color: $blue-grey;

                        &:hover {
                            color: $secondary;
                        }
                    }

                    span {
                        display: block;
                        font-weight: .9rem;
                        color: $muted-grey;
                    }
                }

                //Action bar
                .top-actions {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;

                    .dropdown {
                        margin-left: 6px;
                    }

                    //Single action
                    .top-action {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 34px;
                        width: 34px;
                        min-width: 34px;
                        border-radius: 50%;
                        border: 1px solid $placeholder;
                        background: $white;
                        margin-left: 6px;
                        transition: all .3s;

                        //Modifiers
                        &.is-like {
                            &:hover, &.is-active {
                                border-color: $red;
                                background: $red;

                                i {
                                    color: $smoke-white;
                                }
                            }
                        }

                        &.is-secondary {
                            &:hover, &.is-active {
                                border-color: $secondary;
                                background: $secondary;

                                i {
                                    color: $smoke-white;
                                }
                            }
                        }

                        i {
                            font-size: 16px;
                            color: $blue-grey;
                            transition: all .3s;
                        }
                    }
                }
            }

            //Post content
            .post-body {
                margin: 20px 0;
                max-width: 800px;

                blockquote {
                    max-width: 600px;

                    .quote-title {
                        display: flex;
                        align-items: center;
                        font-weight: 500;
                        color: $blue-grey;

                        img {
                            display: block;
                            height: 26px;
                            width: 26px;
                            min-width: 26px;
                            border-radius: 50%;
                            margin-right: 6px;
                        }
                    }
                }
            }

            .edited-text {
                color: $muted-grey;
                font-size: .9rem;
            }
        }
    }
}

/* ==========================================================================
6. Reply box
========================================================================== */

.reply-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100% - 80px);
    margin-left: 80px;
    height: auto;
    padding: 20px;
    min-height: 300px;
    background: $white;
    border-top: 1px solid $fade-grey;
    transition: all .3s;
    transform: translateY(100%);

    //Active state
    &.is-active {
        transform: translateY(0);
    }

    //Inner wrap
    .box-inner {
        position: relative;
        width: 100%;
        height: 100%;

        .reply-form {
            .button-wrap {
                .button {
                    min-width: 100px;
                }
            }
        }

        //Summernote editor custom styles
        .note-editor.note-frame {
            border-color: transparent;
            border-radius: 6px;
            //Toolbar
            .note-toolbar {
                border-radius: 6px 6px 0 0;
                display: flex;
                align-items: center;

                .note-btn-group {
                    display: flex;
                    align-items: center;
                    margin: 0;

                    .note-btn {
                        height: 36px;
                        width: 36px;
                        border-radius: 6px !important;
                        margin: 0 2px !important;
                        padding: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        i {
                            font-size: 12px;

                            &:before {
                                position: relative;
                                top: -1px;
                            }
                        }
                    }
                }
            }
            //Note content
            .note-editing-area {
                overflow: visible;

                .note-placeholder {
                    display: flex;
                    align-items: center;
                    color: $placeholder;

                    img {
                        position: absolute;
                        top: -36px;
                        left: -30px;
                        display: block;
                        height: 26px;
                        width: 26px;
                        border-radius: 50%;
                        margin-right: 6px;
                        z-index: 5;
                    }
                }
            }

            .note-statusbar {
                display: none;
            }
        }
    }
}

//Summernote modals
.note-modal-content {
    .note-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row-reverse;
        padding: 20px;

        h4 {
            font-size: 1.1rem;
            font-family: 'Nexa Bold', sans-serif;
        }

        .close {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 36px;
            width: 36px;
            border-radius: 50%;
            outline: none !important;
            transition: all .3s;
            opacity: 1 !important;
            margin-top: 0 !important;

            &:hover {
                background: lighten($fade-grey, 1%) !important;
            }

            i {
                font-size: 16px;
                opacity: 1 !important;
                color: $blue-grey !important;
            }
        }
    }

    .note-modal-body {
        .note-form-group, .note-group-image-url {
            .note-form-label {
                font-size: 1rem;
                font-family: 'Nexa Bold', sans-serif;
                color: $muted-grey;
            }
        }

        .checkbox {
            display: flex;
            align-items: center;

            label {
                color: $muted-grey;

                input {
                    position: relative;
                    top: 2px;
                    left: -2px;
                }
            }
        }
    }

    .note-modal-footer {
        padding: 20px 30px;
        text-align: left;

        button {
            height: 40px !important;
            min-width: 120px !important;
            border-radius: 100px !important;
            font-weight: 500 !important;
            background: $secondary !important;
            transition: all .3s;
        }
    }
}

/* ==========================================================================
7. Media Queries
========================================================================== */

/* Smartphones (portrait and landscape) ----------- */
@media only screen and (max-width: 767px) {
    //Title
    .forum-title-wrapper {
        &.is-mobile {
            flex-direction: column;

            img {
                margin-right: 0;
            }

            .forum-title {
                text-align: center;

                &.is-topic-title {
                    max-width: 300px !important;
                }
            }

            .actions {
                margin: 20px 0 0 0;
                justify-content: flex-end;
                width: 100%;
            }
        }
    }

    //Container
    .forum-container {
        padding: 10px;

        .forum-channel {
            flex-direction: column;

            .channel-meta {
                margin-left: 0;
                text-align: center;
            }

            .channel-topics {
                margin: 10px auto;
            }
        }

        .topic-card {
            flex-direction: column;

            .topic-owner {
                margin-bottom: 10px;
            }

            .topic-meta {
                margin: 0;

                .topic-title {
                    text-align: center;
                }

                .flex-block {
                    flex-direction: column;
                    margin: 10px 0;

                    span {
                        margin-bottom: 6px;
                    }

                    .tags {
                        margin: 0;
                    }
                }
            }

            .topic-actions {
                margin: 0 20px;
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .im {
                    display: none !important;
                }

                .topic-replies {
                    margin: 0;
                }

                .last-replie {
                    .last-reply-meta {
                        span {
                            font-size: .8rem;
                        }
                    }
                }

                .dropdown {
                    margin: 0;
                }
            }
        }

        .topic-stats {
            flex-direction: column;

            .stat-block {
                margin: 6px auto;

                .stat-meta {
                    min-width: 60px;
                }
            }

            .participants {
                display: none !important;
            }
        }

        .full-topic {
            padding: 10px;

            .post {
                flex-direction: column;

                .post-meta {
                    .post-owner {
                        margin: 0 auto;
                    }
                }

                .post-content {
                    margin-left: 0;

                    .top-wrap {
                        flex-direction: column;
                        margin-top: 10px;

                        .name-wrap {
                            text-align: center;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }

    .forum-wrap {
        .latest-posts {
            .avatars, .latest-meta {
                display: none !important;
            }

            .forum-stats {
                margin: 10px auto;
                justify-content: center;

                .stat {
                    margin: 0 10px;
                }
            }
        }
    }

    .reply-box {
        width: 100%;
        margin-left: 0;
        z-index: 1001;

        .note-toolbar {
            padding-left: 0;
            overflow-x: auto;
        }

        .note-placeholder {
            img {
                display: none !important;
            }
        }
    }
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .forum-channel {
        .top-contributors {
            min-width: 90px;

            img {
                height: 30px !important;
                width: 30px !important;
                min-width: 30px !important;
            }
        }
    }

    .forum-container {
        .topic-card {
            flex-direction: column;

            .topic-owner {
                margin-bottom: 10px;
            }

            .topic-meta {
                margin: 0;

                .topic-title {
                    text-align: center;
                }

                .flex-block {
                    flex-direction: column;
                    margin: 10px 0;

                    span {
                        margin-bottom: 6px;
                    }

                    .tags {
                        margin: 0;
                    }
                }
            }

            .topic-actions {
                margin: 0 20px;
                width: 100%;
                max-width: 490px;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .im {
                    display: none !important;
                }

                .topic-replies {
                    margin: 0;
                }

                .dropdown {
                    margin: 0;
                }
            }
        }

        .topic-stats {
            .stat-block {
                &:nth-child(5), &:nth-child(6) {
                    display: none !important;
                }
            }

            .participants {
                display: none !important;
            }
        }
    }

    .reply-box {
        .note-placeholder {
            img {
                left: -16px !important;
            }
        }
    }
}

/* iPads (landscape) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .topic-card {
        .tags {
            display: none !important;
        }
    }

    .forum-container {
        .topic-stats {
            .stat-block {
                &:nth-child(5), &:nth-child(6) {
                    display: none !important;
                }
            }

            .participants {
                display: none !important;
            }
        }
    }
}
