/*! _dashboard-datatables.scss | Bulkit | CSS Ninja */

/* ==========================================================================
Datatable styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Demo setup
1. Pagination
2. Datatable sorting
3. Ajax Loading bar

=============================================================================
***/

/* ==========================================================================
0. Demo setup
========================================================================== */

.demo-title {
    font-family: 'Nexa Bold', sans-serif;
    font-size: 1.4rem;
    font-weight: 600;
    color: $blue-grey;
    margin-bottom: 20px;
}

/* ==========================================================================
1. Datatable pagination
========================================================================== */

.pagination {
    text-align: center;

    li {
        list-style-type: none;
        float: left;

        a {
            padding: 0 5px;
            cursor: pointer;
        }

        &.active {
            background: $secondary !important;

            a {
                cursor: default;
                color: $smoke-white !important;
            }
        }
    }
}

/* ==========================================================================
2. Datatable sorting
========================================================================== */

.sorting {
    cursor: pointer;
    background-image: url("../images/icons/datatable/sort_both.png");
    background-repeat: no-repeat;
    background-position: center right;
}

.sorting-desc {
    background-image: url("../images/icons/datatable/sort_desc.png");
}

.sorting-asc {
    background-image: url("../images/icons/datatable/sort_asc.png");
}

/* ==========================================================================
3. Ajax Loading bar
========================================================================== */

.datatable-load-bar {
    width: 100%;
    margin: 8px 0;
}

.datatable-load-bar {
    border: 1px solid gray;
    height: 10px;
}

.datatable-load-bar .bar {
    height: 10px;
    background-color: #AEF;
}

.datatable-filter-line {
    background: #fcfcfc;
}

/* ==========================================================================
4. Datatable styles
========================================================================== */

//Wrapper
.table-wrapper {

    //Demo spacing
    &.has-margin-bottom {
        margin-bottom: 40px;
    }

    //Table
    .table {
        display: table;
        width: 100%;

        &.is-datatable {
            th, td {
                vertical-align: middle;
            }

            th {
                padding: 16px 20px;
            }

            td {
                padding: 12px 20px;
            }

            input {
                padding: 6px 8px;
                border-radius: 4px;
                border: 1px solid $placeholder;
                box-shadow: none;
            }

            .datatable-avatar {
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }
        }
    }

    //Pafination settings
    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;

        ul {
            li {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 36px !important;
                height: 36px !important;
                border-radius: 50%;
                margin: 04px;
                background: $fade-grey;

                &.is-selected {
                    background: $primary;

                    a {
                        color: $white;
                    }
                }

                a {
                    color: $blue-grey;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}
